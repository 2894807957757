import React from 'react';

const InstructorDetailsPageTemplate = ({ pageContext }) => {
    const slug = pageContext.slug;

    /**
     * In this template we can access Contentful data one of two ways
     *  - Use the slug to make SDK calls to get data in the client at runtime
     *  - OR, use a gatsby page query to directly query Contenful during build time
     */
    return <h1>Instructor Details Page: {slug}</h1>;
};

export default InstructorDetailsPageTemplate;
